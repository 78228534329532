import _ from 'lodash';

export class TemplateEmailModel {
  constructor({
    tipoEmail,
    nomeExibicao,
    assunto,
    html,
    designJson,
    flagRetornoEmailUsuarioLogado = false,

  }) {
    // popular construtor
    this.tipoEmail = tipoEmail;
    this.nomeExibicao = nomeExibicao;
    this.assunto = assunto;
    this.html = html;
    this.designJson = designJson ? JSON.parse(designJson) : null;
    this.flagRetornoEmailUsuarioLogado = flagRetornoEmailUsuarioLogado;
  }

  get request() {
    let retorno = _.cloneDeep(this);
    retorno.designJson = retorno.designJson ? JSON.stringify(retorno.designJson) : null;
    retorno.html = retorno.html.replace(/@media/g, '@@media');
    return retorno;
  }

}
