<template>
  <div class="teste">
    <div id="template-email-editor">
      <div class="d-flex mb-4">
        <div class="titulo-pagina">
          {{ tituloFormulario }}
        </div>
      </div>
      <v-form
        ref="form"
        class="row mb-2"
      >
        <input-text
          class="col-12 col-md-3"
          :label="$t('modulos.template_email.formulario.tipo_email')"
          :value="$t(`enums.EnumTipoEmail.${tipoEmail}`)"
          disabled
        />
        <input-text
          v-model="form.assunto"
          class="col-12 col-md-6"
          :label="$t('modulos.template_email.formulario.assunto')"
          :disabled="ehVisualizar"
          obrigatorio
        />
        <!-- <input-text
          v-model="form.nomeExibicao"
          class="col-12 col-md-6"
          :label="$t('modulos.template_email.formulario.nome_exibicao')"
          :disabled="ehVisualizar"
          obrigatorio
        /> -->
        <input-radio
          v-model="form.flagRetornoEmailUsuarioLogado"
          :divider="false"
          class="col-12 col-md-3"
          :options="opcoes.booleano"
          :label="$t('modulos.template_email.formulario.retorno_usuario')"
          :disabled="ehVisualizar"
        />
        <!-- <input-text
          v-model="form.assunto"
          class="col-12"
          :label="$t('modulos.template_email.formulario.assunto')"
          :disabled="ehVisualizar"
          obrigatorio
        /> -->
      </v-form>
      <div>
        <div class="d-flex justify-start w-100">
          <span class="label pb-2">{{
            $t('modulos.template_email.formulario.corpo_email')
          }}</span>
        </div>
        <div
          id="template-email"
          class="template-email"
        >
          <aside class="menu-email-editor">
            <div>
              <span class="menu-header">Menu de Variáveis</span>

              <div
                v-for="variavel in variaveisMenu"
                :key="variavel.id"
                :class="{ 'variaveis-item': true, blockHover: ehVisualizar }"
                @click="copiarVariavel(variavel)"
              >
                <span>{{ variavel.descricao }}</span>
                <v-icon size="16">
                  $clipBoardCopy
                </v-icon>
              </div>
            </div>
            <div v-if="variaveisListaMenu.length > 1">
              <span class="menu-header">Variáveis de Lista</span>
              <div
                v-for="variavel in variaveisListaMenu"
                :key="variavel.id"
                :class="{ 'variaveis-item': true, blockHover: ehVisualizar }"
                @click="copiarVariavel(variavel)"
              >
                <span>{{ variavel.descricao }}</span>
                <v-icon size="16">
                  $clipBoardCopy
                </v-icon>
              </div>
            </div>
          </aside>
          <EmailEditor
            ref="emailEditor"
            :class="{ 'email-editor': true, blockHover: ehVisualizar }"
            :appearance="appearance"
            :min-height="minHeight"
            :project-id="projectId"
            :locale="locale"
            :tools="tools"
            :options="options"
            @load="buscarTemplateEmail"
          />
        </div>
      </div>
    </div>
    <!-- <accordion-padrao
      :alterar-cor="true"
      cor-azul-texto
      :titulo="'Exemplos'"
      class="accordion-padrao col-12"
    >
  <div>
    <h3>Uso de </h3>
  </div>
  </accordion-padrao> -->
    <v-divider class="my-5" />
    <div class="d-flex align-center justify-end">
      <template v-if="ehVisualizar">
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="voltar"
        >
          <v-icon>$mdiArrowLeft</v-icon>
          {{ $t('geral.botoes.voltar') }}
        </botao-padrao>
      </template>
      <template v-else>
        <botao-padrao
          outlined
          color="secondary"
          class="mr-2"
          @click="cancelar"
        >
          <v-icon>$mdiCancel</v-icon>
          {{ $t('geral.botoes.cancelar') }}
        </botao-padrao>
        <botao-padrao
          outlined
          class="mr-2"
          @click="testarEmail"
        >
          <v-icon size="20">
            $mdiEmailFastOutline
          </v-icon>
          {{ $t('modulos.template_email.botoes.teste_envio') }}
        </botao-padrao>

        <botao-padrao
          class="mr-2"
          @click="salvarDesign(false)"
        >
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar_continuar') }}
        </botao-padrao>
        <botao-padrao @click="salvarDesign(true)">
          <v-icon>$mdiContentSaveOutline</v-icon>
          {{ $t('geral.botoes.salvar') }}
        </botao-padrao>
      </template>
    </div>
  </div>
</template>

<script>
import { TemplateEmailService } from '@/common/services';
import i18n from '@/i18n';
import { TemplateEmailModel } from '@common/models/cadastros/TemplateEmailModel.js';
import helpers from '@common/utils/helpers';
import { EmailEditor } from 'vue-email-editor';

export default {
  name: 'App',
  components: {
    EmailEditor,
  },
  props: {
    tipoEmail: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: new TemplateEmailModel({}),
      opcoes: {
        booleano: helpers.BoleanoEnum,
      },

      variaveisMenu: [],
      variaveisListaMenu: [],
      editorId: 'template-email-editor',
      minHeight: '95vh',
      locale: 'pt-BR',
      projectId: 0,
      tools: {
        image: {
          enabled: true,
        },
      },

      options: {
        devices: ['desktop'],
      },
      appearance: {
        theme: 'dark',
        panels: {
          tools: {
            dock: 'right',
          },
        },
      },
    };
  },
  computed: {
    tituloFormulario: function () {
      if (this.id) return this.$t('modulos.template_email.titulos.editar');
      return this.$t('modulos.template_email.titulos.editar');
    },
    ehVisualizar: function () {
      return this.$route.name === 'modelo-email-editar-visualizar';
    },
  },
  async mounted() {
    if (this.id) {
      helpers.redirecionarSemPermissao(this, 'EmailTemplate', 'Editar');
      this.buscar(this.id);
    }
    helpers.redirecionarSemPermissao(this, 'EmailTemplate', 'Inserir');
    this.$store.dispatch('Layout/alterarTituloPagina', this.tituloFormulario);
    await this.buscarVariaveis();
  },

  methods: {
    buscarTemplateEmail: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await TemplateEmailService.buscar(this.tipoEmail)
        .then((res) => {
          this.form = new TemplateEmailModel(res.data);
          if (this.form.designJson)
            this.$refs.emailEditor.editor.loadDesign(this.form.designJson);
        })
        .catch((e) => {
          if (e.response.status === 400) {
            this.toastErro(
              this.$t('modulos.template_email.erros.tipo_nao_existe')
            );
            this.$router.push({ name: 'modelo-email' });
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
    async salvarDesign(salvarSair) {
      const valido = this.$refs.form.validate();
      if (!valido) return;
      let validacaoParaSair = false;
      this.$refs.emailEditor.editor.exportHtml(async (design) => {
        this.form.designJson = design.design;
        this.form.html = design.html;
        this.form.tipoEmail = this.tipoEmail;
        await TemplateEmailService.salvar(this.form)
          .then(() => {
            this.toastSucesso(
              this.$t('modulos.template_email.cadastro_sucesso')
            );
            validacaoParaSair = true;
            setTimeout(() => {
              if (salvarSair && validacaoParaSair) {
                this.$router.push({ name: 'modelo-email' });
              }
            }, 300);
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },
    cancelar: function () {
      this.confirmarCancelar().then(() => {
        this.$router.push({ name: 'modelo-email' });
      });
    },
    voltar: function () {
      this.$router.push({ name: 'modelo-email' });
    },
    copiarVariavel: function (item) {
      if (this.ehVisualizar) return;

      //ROTINA ORIGINAL PARA COPIAR O TEXTO PARA A AREA DE TRANSFERENCIA VOLTAR QUANDO A KEL TIVER CERTIFICADO
      // navigator.clipboard
      //   .writeText(item.variavel)
      //   .then(() => {
      //     this.toastSucesso(
      //       this.$t('modulos.template_email.copiado', {
      //         variavel: item.descricao,
      //       })
      //     );
      //   })
      //   .catch(() => {
      //     this.toastErro(this.$t('modulos.template_email.copiado_falha'));
      //   });

      //CÓDIGO CRIADO PARA FAZER O BYPASS NO SERVIDOR DA KEL SEM CERTIFICADO, APOS COLOCAR O CERTIFICADO VOLTAR PARA A FUNÇÃO ACIMA
      const textarea = document.createElement('textarea');
      textarea.value = item.variavel;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);
      textarea.select();

      try {
        const successful = document.execCommand('copy');

        if (successful) {
          this.toastSucesso(
            this.$t('modulos.template_email.copiado', {
              variavel: item.descricao,
            })
          );
        } else this.toastErro(this.$t('modulos.template_email.copiado_falha'));
      } catch (err) {
        this.toastErro(this.$t('modulos.template_email.copiado_falha'));
      }

      document.body.removeChild(textarea);
    },
    buscarVariaveis: async function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      await TemplateEmailService.buscarVariaveis(this.tipoEmail)
        .then((res) => {
          //adicionar função do Razor para o menu
          this.variaveisListaMenu.push({
            id: 'listaValores',
            descricao: i18n.t(`enums.EnumVariaveisTemplateEmail.listaValores`),
            variavel: `<ul>
@foreach (var item in Model.itens)
   {
	<li>
  	@item.codigo, item.tipoItem
  	</li>
	}
</ul>`,
          });
          for (let key in res.data) {
            if (Array.isArray(res.data[key]) && res.data[key].length > 0) {
              this.variaveisMenu.push({
                id: key,
                descricao: i18n.t(
                  `enums.EnumVariaveisTemplateEmail.${key}.${key}`
                ),
                variavel: `Model.${key}`,
              });
              for (let subkey in res.data[key][0]) {
                this.variaveisListaMenu.push({
                  id: subkey,
                  descricao: i18n.t(
                    `enums.EnumVariaveisTemplateEmail.${key}.${subkey}`
                  ),
                  variavel: `@${key}.${subkey}`,
                });
              }
            } else {
              this.variaveisMenu.push({
                id: key,
                descricao: i18n.t(`enums.EnumVariaveisTemplateEmail.${key}`),
                variavel: `@Model.${key}`,
              });
            }
          }
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    testarEmail: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');
      TemplateEmailService.testarEmail(this.tipoEmail)
        .then(() => {
          this.toastSucesso(
            this.$t('modulos.template_email.teste_envio_sucesso')
          );
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },
  },
};
</script>
<style lang="scss">
@media (max-width: 768px) {
  .teste {
    overflow-x: scroll;
  }

  .template-email {
    flex-direction: column;
  }

  .menu-email-editor {
    margin: 0 auto;
    width: 90vw !important;
  }
}

.template-email {
  display: flex;
  gap: 8px;
  width: 100%;
  height: 100%;
  border-radius: 10px;

  .email-editor {
    width: 95vw;
    height: 80vh;
    border-radius: 10px;
  }

  .menu-email-editor {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 20vw !important;
    min-width: 12vw !important;
    border-radius: 10px 10px 0 0;
    background-color: #f5f5f5;
    max-height: 95vh !important;
    overflow-y: auto;

    .menu-header {
      font-size: 1rem;
      font-weight: 400;
      padding: 1rem;
      display: block;
      text-align: center;
      border-radius: 10px 10px 0 0;
      color: white;
      background-color: #35507b;
    }
  }

  .variaveis-item {
    display: flex;
    overflow-y: auto;
    gap: 8px;
    padding: 8px 16px;
    width: 100% !important;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;

    &:hover {
      background-color: #e0e0e0;
    }
  }

  .variaveis-item + .variaveis-item {
    border-top: 1px solid #e0e0e0 !important;
  }

  .blockHover:hover {
    cursor: not-allowed;
  }
}
</style>


